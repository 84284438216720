// if(navigator.userAgent.match(/MSIE 10/i) || navigator.userAgent.match(/Trident\/7\./) || navigator.userAgent.match(/Edge\/12\./)) {
//  $('body').on("mousewheel", function () {
//  event.preventDefault();
//  var wd = event.wheelDelta;
//  var csp = window.pageYOffset;
//  window.scrollTo(0, csp - wd);
//  });
//  }
$(window).on('load', function(){
    var winW = $(window).width();
    if ((winW >= 768) && ($('[data-aboutscrollimage]').length > 0)) {
        var imgH = $('[data-aboutscrollimage]').height();
        var imgW = $('[data-aboutscrollimage]').width();
        var centerY = (1320 * (imgW / 2000) - imgH) / 2;
        var imgY = $('[data-aboutscrollimage]').offset().top;
        $(window).on('scroll', function(){
            parallax();
        });

        function parallax(){
            var scroll = $(window).scrollTop() - 100; //スクロールの位置を取得 
            var diff = parseInt((imgY - scroll) * 0.1 - centerY - 160);
            $('[data-aboutscrollimage]').css({
                'background-position': 'center ' + diff + 'px'
            });
        }
    }
});

